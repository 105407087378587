<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <logo-text />
        </b-link>

        <b-card-title class="mb-1"> Je avontuur start hier! 🚀 </b-card-title>
        <b-card-text class="mb-2"> Maak een account aan. </b-card-text>

        <!-- form -->
        <b-form class="auth-register-form mt-2" @submit.prevent="registerUser">
          <!-- username -->
          <b-form-group label="Voornaam" label-for="firstname">
            <b-form-input
              id="firstname"
              v-model="firstname"
              :class="$v.firstname.$error ? 'is-invalid' : ''"
              name="register-firstname"
              placeholder="Voornaam"
            />
          </b-form-group>

          <b-form-group label="Naam" label-for="name">
            <b-form-input
              id="name"
              v-model="lastname"
              :class="$v.lastname.$error ? 'is-invalid' : ''"
              name="register-name"
              placeholder="Naam"
            />
          </b-form-group>

          <!-- email -->
          <b-form-group label="E-mail" label-for="email">
            <b-form-input
              id="email"
              v-model="email"
              :class="$v.email.$error ? 'is-invalid' : ''"
              name="register-email"
              placeholder="naam@voorbeeld.be"
            />
          </b-form-group>

          <!-- password -->
          <b-form-group label="Wachtwoord" label-for="password">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="password"
                v-model="password"
                :type="passwordFieldType"
                :class="$v.password.$error ? 'is-invalid' : ''"
                class="form-control-merge"
                name="register-password"
                placeholder="············"
              />
              <b-input-group-append
                :class="$v.password.$error ? 'is-invalid' : ''"
                is-text
              >
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <!-- checkbox -->
          <b-form-group
            :class="$v.isTermsConditionAccepted.$error ? 'is-invalid' : ''"
          >
            <b-form-checkbox
              id="register-privacy-policy"
              :class="$v.isTermsConditionAccepted.$error ? 'is-invalid' : ''"
              v-model="isTermsConditionAccepted"
              name="checkbox-1"
            >
              Ik ga akkoord met de
              <b-link>privacy verklaring &amp; gebruiksvoorwaarden</b-link>
            </b-form-checkbox>
          </b-form-group>

          <!-- submit button -->
          <b-button variant="primary" block type="submit" :disabled="loading">
            <b-spinner v-if="loading" small class="mr-1" /> Registreren
          </b-button>
        </b-form>

        <b-card-text class="text-center mt-2">
          <span>Heb je al een account? </span>
          <b-link :to="{ name: 'auth-login' }">
            <span>Meld je aan</span>
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text">of</div>
        </div>

        <!-- social buttons -->
        <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            size="sm"
            variant="dark"
            :disabled="loading"
            @click="loginWithMicrosoft"
          >
            <b-spinner v-if="loading" small></b-spinner>
            <img
              v-else
              height="20px"
              src="@/assets/images/pages/login/microsoft.svg"
            />
          </b-button>
          <b-button
            size="sm"
            variant="googleblue"
            :disabled="loading"
            @click="loginWithApple"
          >
            <b-spinner v-if="loading" small></b-spinner>
            <img
              v-else
              height="20px"
              src="@/assets/images/pages/login/google.svg"
            />
          </b-button>
          <b-button
            size="sm"
            variant="dark"
            :disabled="loading"
            @click="loginWithApple"
          >
            <b-spinner v-if="loading" small></b-spinner>
            <img
              v-else
              height="20px"
              src="@/assets/images/pages/login/apple.svg"
            />
          </b-button>
        </div>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>
</template>

<script>
import { auth, db, getTimestamp } from "@/firebase/firebaseConfig";
import {
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
import LogoText from "@core/layouts/components/LogoText.vue";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    LogoText,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BSpinner,
    // validations
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      email: "",
      firstname: "",
      lastname: "",
      password: "",
      isTermsConditionAccepted: false,
      loading: false,
      next: this.$route.query.to,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    registerUser() {
      if (!this.$v.$invalid) {
        this.loading = true;
        this.$store
          .dispatch("auth/registerUser", {
            firstname: this.firstname,
            lastname: this.lastname,
            email: this.email,
            password: this.password,
          })
          .then(() => {
            this.loading = false;
            this.$router.push(this.$router.currentRoute.query.to || "/");
          })
          .catch((error) => {
            this.loading = false;
            this.error = error;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: error.toString(),
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          });
      } else {
        this.loading = false;
        this.$v.$touch();
      }
    },
    loginWithMicrosoft() {
      if (this.isTermsConditionAccepted) {
        this.loading = true;
        const payload = {
          next: this.next,
          notify: (arg) => this.$toast(arg),
          closeAnimation: () => (this.loading = false),
        };
        this.$store.dispatch("auth/loginMicrosoft", payload);
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text:
              "Je moet akkoord gaan met de privacyverklaring en de algemene voorwaarden.",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      }
    },
    loginWithGoogle() {
      if (this.isTermsConditionAccepted) {
        this.loading = true;
        const payload = {
          next: this.next,
          notify: (arg) => this.$toast(arg),
          closeAnimation: () => (this.loading = false),
        };
        this.$store.dispatch("auth/loginGoogle", payload);
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text:
              "Je moet akkoord gaan met de privacyverklaring en de algemene voorwaarden.",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      }
    },
    loginWithApple() {
      if (this.isTermsConditionAccepted) {
        this.loading = true;
        const payload = {
          next: this.next,
          notify: (arg) => this.$toast(arg),
          closeAnimation: () => (this.loading = false),
        };
        this.$store.dispatch("auth/loginApple", payload);
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text:
              "Je moet akkoord gaan met de privacyverklaring en de algemene voorwaarden.",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
  validations: {
    firstname: {
      minLength: minLength(2),
      required,
    },
    lastname: {
      minLength: minLength(2),
      required,
    },
    email: {
      required,
      email,
    },
    password: {
      minLength: minLength(8),
      required,
    },
    isTermsConditionAccepted: {
      required,
      sameAs: sameAs(() => true),
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
